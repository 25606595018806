import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import LogoHeaderTransparent from "assets/logo/logo-transparent.png";
import {mediaQuery} from "components/common/styled/mediaQuery";
import routes from "consts/routes";

const LogoContainer = styled.div`
    img {
        height: 3.625rem;
        ${mediaQuery.phone`height: 2.5rem;`}
    }
`;

export default props => (
    <LogoContainer>
        <Link id="tabseraLogo" to={routes.mainDashboard.path}>
            <img src={LogoHeaderTransparent} alt={"logo"} {...props} />
        </Link>
    </LogoContainer>
);
