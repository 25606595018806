import {Network} from "./Network";

class ChatNetwork extends Network {
    constructor() {
        super();
        this.apiRoot = this.getApiRoot();
    }

    getApiRoot() {
        if (process.env.NODE_ENV === "development") {
            return "/chatApi/v1";
        }

        // return "https://local-tabsera.staging.forasoft.com/chatApi/v1";
        return process.env.NODE_ENV === "development"
            ? `${location.protocol}//${location.hostname}:8892/chatApi/v1`
            : `${location.origin}/chatApi/v1`;
    }
}

export default new ChatNetwork();
