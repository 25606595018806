import routes from "../../consts/routes";

const routesWithoutHeader = [routes.signin.path, routes.signup.path, routes.reset.path];
const routesSMS = [
    routes.mainDashboard.path,
    routes.subjects.path,
    routes.subjectsAdd.path,
    routes.subjectsEdit.path,
    routes.subjectsAddCurriculum.path,
    routes.subjectsEditCurriculum.path,
    routes.classes.path,
    routes.classesAdd.path,
    routes.classesEdit.path
];

function pathToRegex(path) {
    return new RegExp(
        "^" +
        path.replace(/:\w+/g, "([a-zA-Z0-9]+)") + // Replace :id with a capture group
            "$"
    );
}

function matchRoute(currentRoute, routes) {
    for (const route of routes) {
        const routeRegex = pathToRegex(route);
        if (routeRegex.test(currentRoute)) {
            return true; // If there's a match, return true
        }
    }
    return false; // No match found
}

export {routesWithoutHeader, routesSMS, matchRoute};
