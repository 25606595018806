import React from "react";
import AsyncStateComponent from "contexts/common/AsyncStateComponent";
import NetworkController from "controllers/Network";
import ChatNetwork from "controllers/ChatNetwork";
import ChatSocketController from "controllers/ChatSocket";
import {withRouter} from "react-router-dom";
import {withContextConsumer} from "../../utils/contexts";
import CurrentUserContext from "../CurrentUser";
import NotificationsContext from "contexts/Notifications";
import {saveAs} from "file-saver";
import FileUploadContext from "../common/FileUploadProgress";
import throttle from "lodash/throttle";

const ChatContext = React.createContext("chat");

@withContextConsumer(CurrentUserContext.Consumer)
@withContextConsumer(NotificationsContext.Consumer)
@withContextConsumer(FileUploadContext.Consumer)
class ChatProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.initState = {
            widgetIsOpen: false,
            dialogs: new Map(),
            chatState: [],
            dialogState: null,
            supportUser: null,
            additionalInfoMessages: new Map(),
            meta: {},
            lectureId: null
        };

        this.state = {
            prepareConnection: this.prepareConnection.bind(this),
            openDialogWith: this.openDialogWith.bind(this),
            enterChat: this.enterChat.bind(this),
            reloadChatState: this.reloadChatState.bind(this),
            closeDialog: this.closeDialog.bind(this),
            openPrivateDialogWith: this.openPrivateDialogWith.bind(this),
            sendMessage: this.sendMessage.bind(this),
            downloadFile: this.downloadFile.bind(this),
            clearMessages: this.clearMessages.bind(this),
            loadNextMessages: this.loadNextMessages.bind(this),
            searchInMessages: throttle(this.searchInMessages.bind(this), 500),
            uploadAttachedFiles: this.uploadAttachedFiles.bind(this),
            openAfterConnect: this.openAfterConnect.bind(this),
            closeDialogs: this.closeDialogs.bind(this),
            getMeta: this.getMeta.bind(this),
            blockUser: this.blockUser.bind(this),
            unblockUser: this.unblockUser.bind(this),
            blockUserGroup: this.blockUserGroup.bind(this),
            unblockUserGroup: this.unblockUserGroup.bind(this),
            disconnect: this.disconnect.bind(this),
            toggleWidget: this.toggleWidget.bind(this),
            updateUserGlobalBlockChatState: this.updateUserGlobalBlockChatState.bind(this),
            closeWidget: this.closeWidget.bind(this),
            setLectureId: this.setLectureId.bind(this),
            ...this.initState
        };
    }

    async prepareConnection() {
        const {response} = await NetworkController.post("/chats/connection");
        const {chatConnectionInfo} = response;
        const {token, connectionUrl} = chatConnectionInfo;
        await this.setStatePromise({chatConnectionInfo});

        await ChatSocketController.connect(connectionUrl, token);

        ChatSocketController.on("connect", async () => {
            await this.enterChat(this.state.receiverIdReadyToConnect);
        });
        ChatSocketController.on("disconnect", async () => {
            this.removeChatEventsListener();
        });

        await this.getMeta();
        await this.getSupportUsers();
        this.listenChatEvent();
    }

    async getMeta() {
        const {response} = await ChatNetwork.get("/file/meta");
        await this.setStatePromise({meta: response?.meta});
    }

    async getSupportUsers() {
        const {response} = await NetworkController.get("/users/support");
        await this.setStatePromise({supportUser: response.users[0]});
    }

    async downloadFile(fileUrl, filename) {
        await saveAs(fileUrl, filename);
    }

    async loadNextMessages(dialogId, lastMessageDate) {
        const data = {
            lastMessageDate,
            dialogId
        };

        const {response} = await ChatSocketController.emit("chat:getOlderMessages", data);
        const {messages} = response;
        const dialogState = this.state.dialogs.get(dialogId);
        dialogState.messages.push(...messages);
        dialogState.allMessagesLoaded = messages.length === 0;
        this.updateDialogMap(dialogState);
    }

    listenChatEvent() {
        ChatSocketController.on("chat:message", async data => {
            this.newMessageReceived(data);
        });

        ChatSocketController.on("chat:dialog", async data => {
            this.newDialogReceived(data);
        });

        ChatSocketController.on("chat:updateDialog", async data => {
            this.updatedDialogReceived(data);
        });

        ChatSocketController.on("chat:changeBlockState", async data => {
            this.updateDialogBlockState(data);
        });

        ChatSocketController.on("chat:changeGroupBlockState", async data => {
            this.updateGroupBlockState(data);
        });

        ChatSocketController.on("chat:applyUserGlobalBlockChatState", async data => {
            await this.applyUserGlobalBlockChatState(data);
        });
    }

    async applyUserGlobalBlockChatState(data) {
        const {currentUser, setCurrentUser} = this.props;
        currentUser.blockedFromChat = data.blockedFromChat;
        await setCurrentUser({...currentUser});
    }

    async updatedDialogReceived(dialog) {
        const {chatState} = this.state;
        let newChatState = [...chatState, dialog];
        if (chatState.find(oldDialog => oldDialog.id === dialog.id)) {
            newChatState = chatState.map(oldDialog =>
                oldDialog.id === dialog.id
                    ? {...dialog, course: oldDialog.course, messageSearch: oldDialog.messageSearch}
                    : oldDialog
            );
        }
        await this.setStatePromise({chatState: await this.sortChatState(newChatState)});
    }

    async setLectureId(lectureId) {
        await this.setStatePromise({lectureId: lectureId});
    }

    async sortChatState(chatState) {
        return chatState.sort((s0, s1) => new Date(s1.lastMessageAt) - new Date(s0.lastMessageAt));
    }

    async updateDialogBlockState({dialogId, isBlocked}) {
        const {dialogs} = this.state;
        const dialogState = dialogs.get(dialogId);
        if (dialogState) {
            dialogState.blockState.userBlocked = isBlocked;
            await this.updateDialogMap(dialogState);
        }
    }

    async updateGroupBlockState({dialogId, isBlocked}) {
        const {currentUser} = this.props;
        const {dialogs} = this.state;
        const dialogState = dialogs.get(dialogId);
        if (!dialogState) return;

        if (isBlocked) dialogState.blockedUsers.push(currentUser.id);
        else dialogState.blockedUsers = dialogState.blockedUsers.filter(item => item !== currentUser.id);

        await this.updateDialogMap(dialogState);
    }

    async newMessageReceived(message) {
        const {dialogs, chatState} = this.state;
        if (dialogs.has(message.dialogId)) {
            const dialog = dialogs.get(message.dialogId);
            const messageAuthorExist = dialog.users.find(({id}) => id === message.from);
            if (!messageAuthorExist) await this.openDialogWith(dialog.user.id);
            dialog.messages.unshift(message);
            await this.readMessage(message, chatState);
            await this.setStatePromise({dialogs});
        }
    }

    async readMessage(message, chatState) {
        const {unreadMessageCount} = await ChatSocketController.emit("chat:readMessage", {
            dialogId: message.dialogId,
            messageId: message.id
        });
        chatState.forEach(dialog => {
            if (dialog.id === message.dialogId) {
                dialog.unreadMessageCount = unreadMessageCount;
            }
        });
        await this.setStatePromise({chatState});
        await this.props.updateChatNotificationView();
    }

    async blockUser(dialogState) {
        this.changeDialogUserBlockState(true, dialogState);
    }

    async unblockUser(dialogState) {
        this.changeDialogUserBlockState(false, dialogState);
    }

    async blockUserGroup(user, dialogState) {
        await this.changeGroupUserBlockState(true, user, dialogState);
    }

    async unblockUserGroup(user, dialogState) {
        await this.changeGroupUserBlockState(false, user, dialogState);
    }

    async openAfterConnect(receiverId) {
        await this.setStatePromise({receiverIdReadyToConnect: receiverId, dialogs: new Map(), dialogState: null});
    }

    async changeGroupUserBlockState(isBlocked, user, dialogState) {
        const data = {isBlocked, blockUserId: user.id, dialogId: dialogState.dialogId};
        await ChatSocketController.emit("chat:changeGroupBlockState", data);
        if (isBlocked) dialogState.blockedUsers.push(user.id);
        else dialogState.blockedUsers = dialogState.blockedUsers.filter(item => item !== user.id);
        await this.updateDialogMap(dialogState);
    }

    async closeDialogs() {
        this.setStatePromise({dialogs: new Map()});
    }

    async changeDialogUserBlockState(isBlocked, dialogState) {
        const data = {blockUserId: dialogState.user.id};
        if (isBlocked) {
            await NetworkController.post("/users/block", data);
        } else {
            await NetworkController.delete("/users/block", data);
        }
        await ChatSocketController.emit("chat:changeBlockState", {isBlocked, ...data});
        dialogState.blockState.contactBlocked = isBlocked;
        await this.updateDialogMap(dialogState);
    }

    async updateUserGlobalBlockChatState(data) {
        await ChatSocketController.emit("chat:updateUserGlobalBlockChatState", data);
    }

    async newDialogReceived(dialog) {
        const {currentUser} = this.props;
        const {chatState} = this.state;
        await this.setStatePromise({chatState: await this.sortChatState([dialog, ...chatState])});
        if (currentUser.id !== dialog.user.id) {
            await this.updateDialogMap({dialogId: dialog.id, ...dialog});
        }
    }

    async enterChat(receiverId) {
        const data = {};
        const {response} = await ChatSocketController.emit("chat:enter", data);
        const chatState = response.dialogs;
        await this.setStatePromise({chatState});
        await this.openPrivateDialogWith(receiverId);
    }

    async reloadChatState() {
        const {response} = await ChatSocketController.emit("chat:enter");
        await this.setStatePromise({chatState: response.dialogs});
    }

    async openPrivateDialogWith(contactId) {
        await this.setStatePromise({dialogs: new Map(), receiverIdReadyToConnect: null});
        if (this.state.lectureId) {
            await this.openDialogWith(contactId, null, false, this.state.lectureId);
        } else {
            await this.openDialogWith(contactId);
        }
    }

    async openDialogWith(contactId, additionalInfo, isPhoneResolution, lectureId) {
        if (!contactId) {
            await this.setStatePromise({dialogState: null});
            return;
        }

        if (lectureId) {
            await this.setStatePromise({lectureId: lectureId});
        }

        if (isPhoneResolution) {
            window.location = `/chat/${contactId}`;
        }

        const {currentUser} = this.props;
        const data = {contactId};

        if (lectureId) {
            data["lectureId"] = lectureId;
        }

        const {error, response} = await ChatSocketController.emit("chat:open", data);
        if (error) {
            return;
        }

        const {dialog, messages} = response;
        const oldDialog = this.state.chatState.find(oldDialog => oldDialog.id === dialog.id);
        const user = this.getDialogUser(oldDialog, dialog, currentUser);

        const dialogState = {
            user,
            messages,
            dialogId: dialog.id,
            users: dialog.users,
            blockState: dialog.blockState,
            parent: dialog.parent,
            blockedUsers: dialog.blockedUsers,
            group: dialog.course
        };
        const chatState = this.state.chatState.map(oldDialog =>
            oldDialog.id === dialog.id ? {...dialog, course: oldDialog.course, messageSearch: oldDialog.messageSearch} : oldDialog
        );

        await this.saveAdditionalInfo(contactId, additionalInfo);
        await this.updateDialogMap(dialogState);
        await this.setStatePromise({chatState});
        await this.props.updateChatNotificationView();
    }

    getDialogUser(oldDialog, dialog, currentUser) {
        if (dialog.parent) {
            const dialogUser = dialog.users.find(user => !user.isDeleted && currentUser.id !== user.id);
            if (!dialogUser) {
                return dialog.users.find(user => currentUser.id !== user.id);
            }
            return dialogUser;
        }
        return (oldDialog && oldDialog.course) || dialog.users.find(({id}) => currentUser.id !== id);
    }

    closeDialog(dialogId) {
        const {dialogs} = this.state;
        dialogs.delete(dialogId);
        this.setState({dialogs});
    }

    async saveAdditionalInfo(contactId, additionalInfo) {
        const {additionalInfoMessages} = this.state;
        if (additionalInfo) additionalInfoMessages.set(contactId, additionalInfo);
        else additionalInfoMessages.delete(contactId);
        await this.setStatePromise({additionalInfoMessages});
    }

    async updateDialogMap(dialogState) {
        const {dialogs} = this.state;
        const {dialogId} = dialogState;
        dialogs.delete(undefined);
        if (!dialogs.has(dialogState.dialogId)) {
            await this.setStatePromise({dialogState});
        }
        dialogs.set(dialogId, dialogState);
        await this.setStatePromise({dialogs, widgetIsOpen: true});
    }

    async sendMessage(message, libraryFiles, attachedFiles) {
        const {dialogs, additionalInfoMessages, lectureId} = this.state;
        const {dialogId} = message;
        const dialogState = dialogs.get(dialogId);
        const receiverId = dialogState.user.id;
        const libraryFilesIds = libraryFiles.map(file => file.id);

        const {response} = await this.uploadAttachedFiles({dialogId: dialogId}, attachedFiles);
        const attachedFilesIds = response.files;

        const data = {
            receiverId,
            dialogId,
            message,
            libraryFiles: libraryFilesIds,
            attachedFiles: attachedFilesIds
        };

        if (lectureId) {
            data["lectureId"] = lectureId;
        }

        const additionalInfoMessage = additionalInfoMessages.get(receiverId);
        if (additionalInfoMessage && !additionalInfoMessage.withoutSend) {
            data.additionalInfo = additionalInfoMessage;
        }
        const {error} = await ChatSocketController.emit("chat:sendMessage", data);
        if (error) {
            dialogState.blockState.userBlocked = true;
            await this.updateDialogMap(dialogState);
        }
        await this.saveAdditionalInfo(receiverId, null);
    }

    async searchInMessages(searchQuery) {
        searchQuery = searchQuery.trim();
        if (!searchQuery.length) return;
        const {
            response: {resultDialogsIds}
        } = await ChatSocketController.emit("chat:searchInMessages", {searchQuery});
        const {chatState} = this.state;
        chatState.forEach(chat => {
            chat.messageSearch = resultDialogsIds.includes(chat.id);
        });
        await this.setStatePromise({chatState});
    }

    async uploadAttachedFiles(body, files) {
        const params = {url: "/file/items", body, files};
        return await this.props.uploadFilesWithProgressToChat(params);
    }

    async clearMessages(dialogId) {
        const data = {dialogId};
        await ChatSocketController.emit("chat:clearMessages", data);
        await this.enterChat();
    }

    removeChatEventsListener() {
        ChatSocketController.removeAllListeners("connect");
        ChatSocketController.removeAllListeners("disconnect");
        ChatSocketController.removeAllListeners("chat:message");
        ChatSocketController.removeAllListeners("chat:dialog");
        ChatSocketController.removeAllListeners("chat:changeBlockState");
        ChatSocketController.removeAllListeners("chat:changeGroupBlockState");
        ChatSocketController.removeAllListeners("chat:applyUserGlobalBlockChatState");
    }

    disconnect() {
        ChatSocketController.disconnect();
        this.setState(this.initState);
    }

    async toggleWidget() {
        const {closeDialogs, widgetIsOpen} = this.state;
        if (widgetIsOpen) await closeDialogs();
        await this.setStatePromise({widgetIsOpen: !widgetIsOpen});
    }

    async closeWidget() {
        const {closeDialogs, widgetIsOpen} = this.state;
        if (widgetIsOpen) await closeDialogs();
        await this.setStatePromise({widgetIsOpen: false});
    }

    render() {
        return <ChatContext.Provider value={this.state}>{this.props.children}</ChatContext.Provider>;
    }
}

export default {Provider: withRouter(ChatProvider), Consumer: ChatContext.Consumer};
