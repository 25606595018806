import React, {Component} from "react";
import styled from "styled-components";
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import EllipsisText from "components/common/styled/EllipsisText";
import UserPicContainer from "components/common/styled/UserPicContainer";
import {isAdmin, isParent, isPrincipal, isPublisher, isStudent, isTeacher} from "consts/userTypes";
import DropdownMenu from "components/common/DropdownMenu";
import {mediaQuery} from "components/common/styled/mediaQuery";
import routes from "consts/routes";
import PersonIcon from "@mui/icons-material/Person";

const ProfileDropdownContainer = styled.div`
    ${mediaQuery.phone`display: none;`}
`;

const ProfileInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.isBlack && "color: black;"};
    ${props => !props.isSms && "min-width: 7rem;"};

    height: 4rem;
    padding: 0 0.5rem;
`;

const UserName = styled.div`
    ${EllipsisText};
    word-break: break-word;
    /* white-space: normal; */
    white-space: nowrap;
    max-width: 8rem;
    max-height: 2.8rem;

    @media (min-width: 1033px) and (max-width: 1258px) {
        white-space: nowrap;
    }
    color: var(--black);
`;

const UserPic = styled.div`
    ${UserPicContainer};

    border: 0;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;

    ${props => (props.dir === "rtl" ? "margin-right" : "margin-left")}: 1rem;

    /* background-color: white; */
    background-color: var(--main-theme-color);
`;

const UserPicSMS = styled.div`
    ${props =>
        props.userPic ? UserPicContainer : "display: flex; align-items: center; justify-content: center; border-radius: 50%;"};

    border: 0;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    color: var(--main-theme-color);

    ${props => (props.dir === "rtl" ? "margin-right" : "margin-left")}: 1rem;

    background-color: var(--light-gray2);
`;

const DropdownButton = styled.div`
    border-top: 1px solid #eaeaea;
    color: black;
    text-align: center;
    padding: 0.6rem 0;

    &:hover {
        background-color: #eaeaea;
    }
`;

@withRouter
@withNamespaces("translation")
class ProfileDropdown extends Component {
    getProfileInfo(isBlack) {
        const {i18n, user, isSms} = this.props;
        return (
            <ProfileInfo isBlack={isBlack} isSms={isSms}>
                {!isSms && <UserName>{user.firstName}</UserName>}
                {isSms ? (
                    <UserPicSMS dir={i18n.dir()} userPic={user.userPic}>
                        {user.userPic ? <img src={user.userPic} alt={"avatar"} /> : <PersonIcon />}
                    </UserPicSMS>
                ) : (
                    <UserPic dir={i18n.dir()}>{user.userPic && <img src={user.userPic} alt={"avatar"} />}</UserPic>
                )}
            </ProfileInfo>
        );
    }

    getItems() {
        const {t, user, logout, history} = this.props;
        const items = [];
        if (!isAdmin(user.role)) {
            items.push(
                <DropdownButton
                    id="registeredHeaderProfileMenuProfileButton"
                    key="profile"
                    onClick={() => history.push(routes.profile.path)}>
                    {t("navigation.userMenu.profile")}
                </DropdownButton>
            );

            const isUserSchoolTeacher = isTeacher(user.role) && user.isSchoolAttendee;
            const isUserSchoolStudent = isStudent(user.role) && user.isSchoolAttendee;
            if (!isUserSchoolTeacher && !isUserSchoolStudent) {
                items.push(
                    <DropdownButton key="wallet" onClick={() => history.push(routes.wallet.path)}>
                        {t("navigation.userMenu.wallet")}
                    </DropdownButton>
                );
            }

            if (!isPublisher(user.role) && !isUserSchoolStudent) {
                items.push(
                    <DropdownButton key="publisherContent" onClick={() => history.push(routes.content.path)}>
                        {t("navigation.userMenu.myContent")}
                    </DropdownButton>
                );
            }
            if (isTeacher(user.role)) {
                items.push(
                    <DropdownButton key="quizzes" onClick={() => history.push(routes.quizzes.path)}>
                        {t("navigation.userMenu.quizzes")}
                    </DropdownButton>
                );
            }
            if (isTeacher(user.role)) {
                items.push(
                    <DropdownButton key="assignments" onClick={() => history.push(routes.assignments.path)}>
                        {t("navigation.userMenu.assignments")}
                    </DropdownButton>
                );
            }

            if (isTeacher(user.role) || isPrincipal(user.role)) {
                items.push(
                    <DropdownButton key="weightageCategory" onClick={() => history.push(routes.weightageCategory.path)}>
                        {t("navigation.userMenu.weightageCategory")}
                    </DropdownButton>
                );
            }

            if (isPrincipal(user.role)) {
                items.push(
                    <DropdownButton key="gradingSchema" onClick={() => history.push(routes.gradingSchema.path)}>
                        {t("navigation.userMenu.gradingSchema")}
                    </DropdownButton>
                );
            }

            if (isTeacher(user.role)) {
                items.push(
                    <DropdownButton key="assessments" onClick={() => history.push(routes.assessments.path)}>
                        {t("navigation.userMenu.assessments")}
                    </DropdownButton>
                );
            }

            if (isTeacher(user.role) || isPrincipal(user.role)) {
                items.push(
                    <DropdownButton key="library" onClick={() => history.push(routes.library.path)}>
                        {t("navigation.userMenu.library")}
                    </DropdownButton>
                );
            }
            if (isTeacher(user.role)) {
                items.push(
                    <DropdownButton key="invitations" onClick={() => history.push(routes.invitations.path)}>
                        {t("navigation.userMenu.invitations")}
                    </DropdownButton>
                );
            }
            if (isParent(user.role)) {
                items.push(
                    <DropdownButton
                        id="registeredHeaderProfileMenuChildrenButton"
                        key="children"
                        onClick={() => history.push(routes.children.path)}>
                        {t("navigation.userMenu.children")}
                    </DropdownButton>
                );
            }
        }
        items.push(
            <DropdownButton id="registeredHeaderProfileMenuLogoutButton" key="logout" onClick={logout}>
                {t("navigation.userMenu.logout")}
            </DropdownButton>
        );
        return items;
    }

    render() {
        return (
            <ProfileDropdownContainer>
                <DropdownMenu
                    buttonContent={this.getProfileInfo()}
                    titleItem={this.getProfileInfo(true)}
                    items={this.getItems()}
                />
            </ProfileDropdownContainer>
        );
    }
}

export default ProfileDropdown;
