import React, {Component} from "react";
import styled from "styled-components";
import Status from "components/common/icons/notifications/statuses/status";
import {Manager, Popper, Reference} from "react-popper";
import {withNamespaces} from "react-i18next";
import {withContextConsumer} from "utils/contexts";
import {withRouter} from "react-router-dom";
import LocaleContext from "contexts/common/LocaleContext";
import notificationsType from "consts/notificationsType";
import CurrentUserContext from "contexts/CurrentUser";
import HeaderWalletPreview from "./HeaderWalletPreview";
import {getLinkFromTitle, renderDate} from "../common/utils";
import OutsideClickHandler from "react-outside-click-handler";
import Popover from "./Popover";
import PopoverRow from "./PopoverRow";
import {Arrow} from "components/common/popperMenu/Arrow";
import {IconWrapper, NotificationWrapperSms} from "./style";

const ContainerActions = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    justify-content: space-between;

    padding: 1rem;
`;

const Text = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    color: #8b8b8b;
    width: 7rem;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: ${props => (props.dir === "ltr" ? "right" : "left")};
`;

const Button = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    text-align: center;
    color: var(--main-theme-color);
    cursor: pointer;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify: flex-start;
    cursor: pointer;
`;

const TextLink = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    text-align: center;
    color: var(--main-theme-color);
    cursor: pointer;
    text-decoration: none;
`;

@withRouter
@withContextConsumer(LocaleContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
@withNamespaces("translation")
class PopUpNotification extends Component {
    constructor(props) {
        super(props);
    }

    handlerViewAll() {
        const {history, handlerOpen, type} = this.props;
        handlerOpen(type);
        this.handlerOnClose();
        if (type === notificationsType.allNotifications) history.push("/notifications/courses");
    }

    handlerClearAllInPreview() {
        const {handlerOpen, type, clearByTypeAndIds, headerNotifications} = this.props;
        if (notificationsType.allNotifications) {
            handlerOpen(notificationsType.allNotifications);
            return clearByTypeAndIds(type, headerNotifications.map(n => n.id));
        }
        handlerOpen(type);
        clearByTypeAndIds(type, headerNotifications.map(n => n.id));
    }

    handlerOnOpen() {
        const {handlerOpen, type} = this.props;
        if (notificationsType.allNotifications) {
            return handlerOpen(notificationsType.allNotifications);
        }
        handlerOpen(type);
    }

    handlerOnClose() {
        const {handlerClose, type} = this.props;
        if (notificationsType.allNotifications) {
            return handlerClose(notificationsType.allNotifications);
        }
        handlerClose(type);
    }

    getTextNotification(notification) {
        const {t} = this.props;
        const text = notification.about === "course" ? notification.courseName : notification.lectureName;
        return (
            <React.Fragment>
                {notification.courseId && <TextLink>{text}</TextLink>}
                {t(`notifications.titles.${notification.title}`, {
                    courseName: notification.courseName,
                    teacher: notification.teacherName,
                    quiz: notification.quizName
                })}
                {notification.lectureId && <TextLink>{text}</TextLink>}
            </React.Fragment>
        );
    }

    render() {
        const {newNotification, headerNotifications, children, arrow, history, isOpen, currentUser, i18n, t, isSms} = this.props;
        const statusStyle = i18n.dir() === "rtl" ? {marginLeft: "1rem"} : {marginRight: "1rem"};
        Object.assign(statusStyle, {minWidth: "20px", height: "20px"});
        const isEmpty = !(headerNotifications && headerNotifications.length > 0);
        return (
            <div style={{position: "relative", marginLeft: isSms ? "auto" : ""}}>
                <Manager>
                    <OutsideClickHandler onOutsideClick={() => isOpen && this.handlerOnClose()}>
                        <Reference>
                            {({ref}) =>
                                isSms ? (
                                    <NotificationWrapperSms
                                        ref={ref}
                                        onClick={isOpen ? this.handlerOnClose.bind(this) : this.handlerOnOpen.bind(this)}>
                                        {children}
                                    </NotificationWrapperSms>
                                ) : (
                                    <IconWrapper
                                        ref={ref}
                                        onClick={isOpen ? this.handlerOnClose.bind(this) : this.handlerOnOpen.bind(this)}>
                                        {children}
                                    </IconWrapper>
                                )
                            }
                        </Reference>
                        <Popper placement="bottom-end" modifiers={{offset: {offset: "15", enable: true}}}>
                            {({ref}) =>
                                newNotification && !isOpen ? (
                                    <div>
                                        <Popover ref={ref} dir={i18n.dir()} isPopUp>
                                            <PopoverRow isPopUp>
                                                <Content>
                                                    <Status status={newNotification.status} style={statusStyle} />
                                                    <div>{this.getTextNotification(newNotification)}</div>
                                                </Content>
                                            </PopoverRow>
                                            <Arrow place={arrow} dir={i18n.dir()} />
                                        </Popover>
                                    </div>
                                ) : null
                            }
                        </Popper>
                        <Popper>
                            {({ref}) =>
                                isOpen ? (
                                    <div>
                                        <Popover ref={ref} dir={i18n.dir()}>
                                            <HeaderWalletPreview />
                                            {headerNotifications.map(notification => {
                                                const link = getLinkFromTitle(notification.title, {
                                                    ...notification,
                                                    currentUser
                                                });
                                                const createdAt = new Date(notification.createdAt);
                                                return (
                                                    <PopoverRow key={notification.id} style={{borderBottom: "1px solid #ebebeb"}}>
                                                        <Content onClick={() => history.push(link)}>
                                                            <Status
                                                                viewed={notification.isViewed ? 1 : 0}
                                                                status={notification.status}
                                                                style={statusStyle}
                                                            />
                                                            <div>{this.getTextNotification(notification)}</div>
                                                        </Content>
                                                        <Text dir={i18n.dir()}>{renderDate(createdAt, t, i18n)}</Text>
                                                    </PopoverRow>
                                                );
                                            })}
                                            {isEmpty && (
                                                <PopoverRow
                                                    style={{
                                                        borderBottom: "1px solid #ebebeb",
                                                        justifyContent: "center"
                                                    }}>
                                                    {t("notifications.emptyList")}
                                                </PopoverRow>
                                            )}
                                            <ContainerActions>
                                                <Button onClick={this.handlerClearAllInPreview.bind(this)}>
                                                    {!isEmpty && t("notifications.clearAll")}
                                                </Button>
                                                <Button onClick={this.handlerViewAll.bind(this)}>
                                                    {t("notifications.viewAll")}
                                                </Button>
                                            </ContainerActions>
                                            <Arrow place={arrow} dir={i18n.dir()} />
                                        </Popover>
                                    </div>
                                ) : null
                            }
                        </Popper>
                    </OutsideClickHandler>
                </Manager>
            </div>
        );
    }
}

export default PopUpNotification;
